@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,700;1,400&display=swap');
html, body{
    margin:0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Nunito', sans-serif;
}

.confirm-box__content span{
    color:black !important
}

.confirm-box__actions [role="confirmable-button"] {
    background-color: rgb(204, 68, 78) !important;
    border: 0px;
    padding:6px 30px;
    color:white;
    border-radius: 4px;
}

.confirm-box__actions [role="cancellable-button"] {
    background-color: rgb(83, 163, 87);
    border: 0px;
    padding:6px 30px;
    color:white;
    border-radius: 4px;
}